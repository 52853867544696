<template>
  <div class="col-lg-3">
    <!-- Simple card -->
    <b-card style="border-radius: 13px">
      <router-link :to="`/award/awardDetail/${award.id}`" style="color: #303030">
        <div>
          <img class="card-img" :src="award.imagesMap.imagepaysage.url" alt="Card image"
            style="object-fit: cover; opacity: 0.9" />
        </div>

        <b-card-title>
          <h5 style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              text-align: center;
            ">
            <span style="color: #07693A" v-if="award.isPublished == true"><i class="mdi mdi-star font-size-18"></i></span>{{ award.name }}
          </h5>
        </b-card-title>
        <b-card-text>
          <h4 style="text-align: center;">
            {{ time }}
          </h4>
        </b-card-text>
      </router-link>
      <div style="display: flex; align-items: center; justify-content: center;">
        <b-button class="c2play-primary">
          <b-dropdown class="card-drop" variant="white" menu-class="dropdown-menu-end" right toggle-class="p-0">
            <template v-slot:button-content>
              <span style="color: #fff"><i class="mdi mdi-dots-horizontal font-size-18"></i></span>
            </template>
            <b-dropdown-item @click="confirm()">{{
              $t("pages.videos.actions.supprimer")
            }}</b-dropdown-item>
          </b-dropdown>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: ""
    };
  },
  props: {
    award: {
      type: Object,
      required: true
    }
  },
  methods: {
    creerMinuteur(dateDebut, dateFin) {
      const differenceEnMillisecondes = dateFin - dateDebut;
      const differenceEnSecondes = Math.floor(differenceEnMillisecondes / 1000);

      let tempsRestant = differenceEnSecondes;
      let minuteur;

      const afficherTempsRestant = temps => {
        const jours = Math.floor(temps / (3600 * 24));
        temps %= 3600 * 24;
        const heures = Math.floor(temps / 3600);
        temps %= 3600;
        const minutes = Math.floor(temps / 60);
        const secondes = temps % 60;

        const tempsRestantFormat =
          jours + "j " + heures + "h " + minutes + "m " + secondes + "s";

        // console.log(`Temps restant : ${tempsRestantFormat}`);
        this.time = tempsRestantFormat;
      };

      const demarrerMinuteur = () => {
        minuteur = setInterval(() => {
          if (tempsRestant > 0) {
            tempsRestant--;
            afficherTempsRestant(tempsRestant);
          } else {
            clearInterval(minuteur);
            this.time = "00j 00h 00m 00s"
            console.log("Minuteur terminé !");
          }
        }, 1000); // Interval d'une seconde (1000 ms)
      };

      // Pour démarrer le minuteur
      demarrerMinuteur();
    }
  },
  mounted() {
    const dateDebut = new Date();
    const dateFin = new Date(this.award.endDate);
    this.creerMinuteur(dateDebut, dateFin);
  }
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>