<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import awardCard from "../../../components/widgets/card/awardCard.vue";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";

export default {
	props: {},
	page: {
		title: "Blog List",
		meta: [{ name: "description", content: appConfig.description }],
	},
	components: {
		Layout,
		PageHeader,
		awardCard,
		loaderProcessVue,
	},
	data() {
		return {
			titre: "",
			progress: 0,
			process: false,
			awards: [],
			isAuthError: false,
			authError: "",
			award: {
				name: "",
				description: "",
				startDate: "",
				endDate: "",
				hasCategorie: false,
				isMultiVote: false,
				isFree: false,
				isMultiSameVote: false,
				votePrice: "",
			},
			addModal: false,
			secondModal: false,
			title: "Liste des compétitions",
			items: [
				{
					text: "Awards",
					// href: "/",
				},
				// {
				//   text: "Articles",
				//   active: true,
				// },
			],
		};
	},
	methods: {
		showAddModal() {
			this.addModal = true
		},
		imgVideoSelect(event) {
			this.imgSelect = event.target.files[0]; // Get the selected image or video
			console.log(this.imgSelect);
		},

		imgVideoSelectPaysage(event) {
			this.imgPaysage = event.target.files[0];
			console.log(this.imgPaysage);
		},
		createAward() {
			console.log(this.award + new Date(this.award.endDate))



			const onUploadProgress = progressEvent => {
				this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
				console.log(`Upload progress: ${this.progress}%`);
			}

			if (
				!this.award.description ||
				!this.award.endDate ||
				!this.award.name ||
				!this.award.startDate ||
				!this.award.votePrice ||
				!this.imgPaysage ||
				!this.imgSelect
			) {

				this.isAuthError = true
				this.authError = "Veuillez remplir tous les champs"
				
			} else {

				this.process = true
				this.addModal = false
				this.titre = "Création de la compétition"
				Api.postFormData("/voting/rest/awards/create-awards", {
					description: this.award.description,
					endDate: new Date(this.award.endDate),
					isFree: this.award.isFree,
					hasCategorie: this.award.hasCategorie,
					imagepaysage: this.imgPaysage,
					imageportrait: this.imgSelect,
					isMultiSameVote: this.award.isMultiSameVote,
					isMultiVote: this.award.isMultiVote,
					name: this.award.name,
					startDate: new Date(this.award.startDate),
					vote_price: this.award.votePrice
				}, onUploadProgress)
					.then((response) => {
						this.process = false
						Swal.fire("Succes!", "Compétition créée avec succès", "success");
						console.log(response);
						location.reload()
					}).catch((error) => {
						this.process = false
						console.log(error)
						Erreur.gestionErreur(error.message)
					})
			}

		},
	},

	mounted() {
		// this.process = true
		this.titre = "Chargement des compétitions"

		Api.get("/voting/rest/awards/all-awards")
			.then((res) => {
				this.process = false
				this.awards = res.data.content;
				console.log(res);
			})
			.catch((error) => {
				this.process = false
				console.log(error);
				Erreur.gestionErreur(error.message);
			});
	},
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<loaderProcessVue :title="titre" :progress="progress" :visible="process"></loaderProcessVue>
		<div class="row justify-content-end">
			<div class="col-lg-10" style="margin-top: 10px">
				<div @click="showAddModal" class="btn c2play-primary" style="position: relative; left: 89%"
					to="/blog/createArticle">
					<span style="color: #fff"><i class="fas fa-plus font-size-18"></i> {{ $t("addButton") }}</span>

				</div>
			</div>
		</div>

		<br/>

		<div class="row">
			<awardCard v-for="award in awards.slice().reverse()" :key="award.id" :award="award"></awardCard>
		</div>

		<b-modal v-model="addModal" id="modal-xl" size="xl" title="Nouvelle compétition" title-class="font-18" hide-footer>
			<!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

			<div class="card-body">
				<b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
					{{ authError }}
				</b-alert>

				<form>
					<div class="form-group row mb-2">
						<div class="col-lg-6">
							<label for="projectname" class="col-form-label">{{
								$t("pages.videos.formulaire.titre.label")
							}}</label><span style="color: red; font-size: 1.2em">*</span>
							<input id="projectname" name="projectname" v-model="award.name" type="text" class="form-control"
								:placeholder="$t('pages.videos.formulaire.titre.placeholder')" />
						</div>

						<div class="form-group col-lg-6 mb-3">
							<label class="col-form-label">Prix de l'accès au vote (XAF)</label>
							<div class="col-lg-12">
								<input id="projectname" v-model="award.votePrice" name="projectname" type="text"
									class="form-control" placeholder="Entrez le prix du vote" />
							</div>
						</div>
					</div>

					<div class="row mb-2">
						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label"> Date de début</label><span
								style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<input id="projectname" v-model="award.startDate" name="projectname" type="datetime-local"
									class="form-control" placeholder="Entre le lien de la vidéo..." />
							</div>
						</div>

						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">Date de fin</label><span
								style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<input id="projectname" v-model="award.endDate" name="projectname" type="datetime-local"
									class="form-control" placeholder="Entre le lien de la vidéo..." />
							</div>
						</div>
					</div>

					<div class="row mb-2">
						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">{{
								$t("pages.videos.formulaire.files.image")
							}} portrait</label><span style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<input id="projectname" name="projectname" type="file" @change="imgVideoSelect($event)"
									class="form-control" placeholder="Entre le lien de la vidéo..."
									accept=".png, .jpeg, .jpg" />
							</div>
						</div>

						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">Image paysage</label><span
								style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<input id="projectname" name="projectname" type="file"
									@change="imgVideoSelectPaysage($event)" class="form-control"
									placeholder="Entre le lien de la vidéo..." accept=".png, .jpeg, .jpg" />
							</div>
						</div>
					</div>



					<div class="row mb-2">
						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label"> Est-elle payante</label><span
								style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<div class="display">
									<div style="margin-right: 20px;">
										<b-form-radio v-model="award.isFree" name="some-radios1"
											value="true">Oui</b-form-radio>
									</div>
									<div>
										<b-form-radio v-model="award.isFree" name="some-radios1"
											value="false">Non</b-form-radio>
									</div>
								</div>
							</div>
						</div>


						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">A-t-elle des categories</label><span
								style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<div class="display">
									<div style="margin-right: 20px;">
										<b-form-radio v-model="award.hasCategorie" name="some-radios2"
											value=true>Oui</b-form-radio>
									</div>
									<div>
										<b-form-radio v-model="award.hasCategorie" name="some-radios2"
											value=false>Non</b-form-radio>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row mb-2">
						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">Peut-on voter pour plusieurs
								candidats</label><span style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<div class="display">
									<div style="margin-right: 20px;">
										<b-form-radio v-model="award.isMultiVote" name="some-radios3"
											value="true">Oui</b-form-radio>
									</div>
									<div>
										<b-form-radio v-model="award.isMultiVote" name="some-radios3"
											value="false">Non</b-form-radio>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">Peut-on voter le même candidat plusieurs
								fois</label><span style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<div class="display">
									<div style="margin-right: 20px;">
										<b-form-radio v-model="award.isMultiSameVote" name="some-radios4"
											value="true">Oui</b-form-radio>
									</div>
									<div>
										<b-form-radio v-model="award.isMultiSameVote" name="some-radios4"
											value="false">Non</b-form-radio>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div class="form-group row mb-2">
						<label for="projectdesc" class="col-form-label">{{
							$t("pages.videos.formulaire.description.label")
						}}</label>
						<div class="col-lg-12">
							<textarea id="projectdesc" v-model="award.description" class="form-control" rows="3"
								:placeholder="$t('pages.videos.formulaire.description.placeholder')
									"></textarea>
						</div>
					</div>
				</form>

				<div class="row">
					<div class="col-lg-12" style="margin-top: 10px">
						<button class="btn c2play-primary" @click="createAward" style="position: relative; left: 45%">
							Créer
						</button>
					</div>
				</div>
			</div>
		</b-modal>

	</Layout>
</template>